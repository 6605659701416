<template>
  <div>
    <v-head></v-head>
  </div>
</template>

<script>
import {  getList } from '@/api/home'
import vHead from '../components/head.vue'
export default {
  components: {
    vHead
  },
  data() {
    return{

    }
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      let data = await getList()
      console.log(data)
    }
  }
}
</script>

<style>

</style>